.WelcomeBanner {
  color: #f4f3f3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85vh;
}

.welcome-text {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 4px;
}

.section-branche {
  position: absolute;
  height: 100%;
  width: 25%;
}

.section-branche:first-child {
  left: 0;
  top: 0;
}

.section-branche:last-child {
  right: 0;
  bottom: 0;
}

.b-1 {
  width: 150px;
}

.b-2 {
  width: 250px;
}

.logo {
  opacity: 0;
  animation: fadeIn 4s ease-in-out forwards;
}

.icon-reseaux {
  opacity: 0;
  animation: fadeIn 6s ease-in-out forwards;
}


/* Keyframes for slide-in animations */


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Media Queries */
@media (max-width: 1024px) {
  .section-branche {
    width: 30%;
  }

  .b-1 {
    width: 100px;
  }

  .b-2 {
    width: 200px;
  }
}

@media (max-width: 767.98px) {
  .WelcomeBanner {
    height: 100vh;
  }

  .welcome-text {
    font-size: 20px;
  }

  .section-hero {
    height: auto;
  }

  .section-branche {
    height: 25vh;
    width: 50%;
  }

  .b-1 {
    width: 70px;
  }

  .b-2 {
    width: 130px;
  }
}

@media (max-width: 376px) {
  .welcome-text {
    font-size: 16px;
  }

  .WelcomeBanner {
    margin-top: 10%;
    height: 50vh;
  }

  .section-hero {
    height: auto;
  }

  .section-branche {
    height: 25vh;
    width: 50%;
  }

  .b-1 {
    width: 50px;
  }

  .b-2 {
    width: 100px;
  }
}
