.fade-in-text {
    opacity: 0;
    transform: translateX(-100px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in-img {
    opacity: 0;
    transform: translateX(100px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}


.fade-in-text.visible {
    opacity: 1;
    transform: translateX(0);
}

.fade-in-img.visible {
    opacity: 1;
    transform: translateX(0);
}

.petals {
    opacity: 0;
    animation: fadeIn 2s ease-in-out forwards;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  