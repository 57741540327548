.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: gray;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 99999999999999999;
  }
  

  .scroll-to-top-button.show {
    opacity: 0.3;
  }
  .scroll-to-top-button.show:hover {
    opacity: 0.8;
  }

  .scroll-to-top-button {
    font-size: 20px;
  }
  